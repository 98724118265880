<template>
    <div>
      <v-btn
        bottom
        class="secondary"
        dark
        fab
        fixed
        right
        id="createUserModalBtn"
        @click="dialog = !dialog"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <div>
      <v-dialog v-model="dialog" persistent width="800px">
        <v-card class="px-5 py-5">
            <card-title :is-image="false" icon="mdi-pencil">{{ isEditing ? 'Edit Band' : 'Create Band'  }}</card-title>
            <p class="mt-4 ml-5 c-blue-text px-4">Band Details</p>
            <v-card-text>
              <v-form ref="form" v-model="valid">
             <div class="tw-flex tw-flex-row">
                <v-text-field
                class="px-4"
                type="number"
                label="From (Band Limit)"
                v-model.trim="band.fromValue"
                placeholder="100,000"
                underlined
                :rules="[required('From')]"
                ></v-text-field>
                <v-text-field
                class="px-4"
                type="number"
                label="To (Band Limit)"
                v-model.trim="band.toValue"
                placeholder="300,000"
                underlined
                :rules="[required('To'),greaterThan('To', band.fromValue)]"
                ></v-text-field>
             </div>
            <div class="tw-grid tw-grid-cols-2">
              <p class="tw-justify-items-center px-4 c-blue-text tw-text-base">Email Address(es):</p>
              <v-btn class="tw-justify-self-end" @click="emailsNo++" color="primary" small>Add Email</v-btn>
            </div>
             <div class="tw-grid tw-grid-cols-3">
               <v-text-field
               v-for="(n,i) in emailsNo"
               :key="i"
               class="px-4"
               label="Add Email"
               v-model="emailArray[i]"
               underlined
               append-outer-icon="mdi-close"
               @click:append-outer="removeEmail(i)"
               :rules="[required('Email'),emailFormat()]"
               ></v-text-field>
             </div>
            </v-form>
            </v-card-text>
            <v-card-actions class="tw-flex tw-justify-center">
                <v-btn 
                v-if="!isEditing"
                @click="createBand"  
                width="150" 
                color="warning" 
                large
                :loading="isLoading"
                :disabled="!valid"
                >Onboard</v-btn>
                <v-btn
                v-else
                width="150" 
                color="warning" 
                large
                :loading="isLoading"
                @click="updateBand"
                :disabled="!valid"
                >Update Band</v-btn>
                <v-btn
                width="150" 
                color="primary" 
                large
                @click="closeDialog"
                >Close</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    </div>
  </template>
  
  <script>
  import validation from '@/utils/Validations'
 //import axios from 'axios'
import CardTitle from '../CardTitle.vue'
import axios from 'axios'


  export default {
  components: { CardTitle },
  props: {editDialog:Boolean,
    currentBand: Object },
    data: () => ({
      valid: false,
      band: {
        description: null,
        toValue: null,
        fromValue: null,
        emailAddresses: null
      },
      emails:null,
      emailsNo: 1,
      emailArray:[],
      dialog: false,
      loading: false,
      isLoading: false,
      dialogInvite: false,
      alert: false,
      ...validation,
    }),

    watch: {
     editDialog() {
      if(this.editDialog === true) {
        this.dialog = true
      }
     },
    },

    computed: {
      isEditing() {
        if(this.editDialog === true){
          this.assignEditBand()
          return true
        } 
        return false
      },
    },
  
    methods: {
      removeEmail(index) {
        this.emailArray.splice(index,1)
        if(this.emailsNo > 1) {
          this.emailsNo--
        }
      },
      assignEditBand() {
         this.band.toValue = this.currentBand.toValue.toString()
         this.band.fromValue  = this.currentBand.fromValue.toString()
         this.band.emailAddresses = this.currentBand.emailAddresses
         const emails = this.currentBand.emailAddresses.split(',')
         this.emailArray = emails
         this.emailsNo = emails.length
      },
      createBand() {
        this.isLoading = true
       const emailJoin = this.emailArray.join(',')
       this.band.emailAddresses = emailJoin
       this.band.description = `Ranges from ${this.band.fromValue} to ${this.band.toValue}`
       axios.post(`/motor-assessment/api/approval-band/create/${this.$store.state.auth.user.sub}`, this.band)
       .then((res) => {
        if(res.data.success) {
          this.$store.commit('SNACKBAR', {
              state: 'success',
              message: res.data.msg,
              variant: 'success',
            })
            this.isLoading = false
            this.$emit('reloadPage')
            this.closeDialog()
        } else {
          this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: res.data.msg,
              variant: 'error',
            })
            this.isLoading = false
        }
       })
       .catch((err) => {
        this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: err.message,
              variant: 'error',
            })
            this.isLoading = false
       })
      },
      updateBand() {
        this.isLoading = true
        const emailJoin = this.emailArray.join(',')
       this.band.emailAddresses = emailJoin
       this.band.description = `Ranges from ${this.band.fromValue} to ${this.band.toValue}`
       const payload = this.band
       axios.put(`/motor-assessment/api/approval-band/update/${this.currentBand.id}/${this.$store.state.auth.user.sub}`, payload)
       .then((res) => {
        if(res.data.success) {
          this.$store.commit('SNACKBAR', {
              state: 'success',
              message: res.data.msg,
              variant: 'success',
            })
            this.isLoading = false
            this.$emit('reloadPage')
            this.closeDialog()
        } else {
                  this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: res.data.msg,
              variant: 'error',
            })
            this.isLoading = false
        }
       })
       .catch((err) => {
        this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: err.message,
              variant: 'error',
            })
            this.isLoading = false
       })
      },
      closeDialog() {
        this.dialog = false
        this.emailsNo = 1
        this.$refs.form.reset()
         this.$emit('closeDialog')
      },
      clear() {
        this.$refs.form.reset()
      },
    },
  }
  </script>
  